$(function(){
  var tabSelect = function(){
    var id = location.hash;
    id = id ? id : $('.js-tab_button--default').attr('href');
    $('.js-tab_button--active').removeClass('js-tab_button--active');
    $('.js-tab_content').hide();
    $(id+'-content').show();
    $('[href="'+id+'"]').addClass('js-tab_button--active');
  };
  $(window).on('hashchange', tabSelect);
  tabSelect();
});
